<template>
  <div class="center">
    <el-form
      :inline="true"
      :model="formInline"
      ref="elfrom"
      class="demo-form-inline"
    >
      <el-form-item label="账户名">
        <el-input
          v-model="formInline.name"
          placeholder="请输入你的开户名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="开户银行">
        <el-select class="bank" v-model="formInline.bank" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :value="item.label"
          >
            <div class="select-div">
              <img :src="item.remarks" alt="" />
              <span>{{ item.label }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支行">
        <el-input
          v-model="formInline.subbranch"
          placeholder="请输入您的支行名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="银行卡号">
        <el-input
          v-model="formInline.number"
          :maxlength="16"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="请输入您的银行卡号"
        ></el-input>
      </el-form-item>
      <el-form-item label="开票类型" v-if="bol">
        <el-select v-model="formInline.billingtype" placeholder="请选择">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :value="item.label"
          >
            <div class="select-div">
              <span>{{ item.label }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票税率" v-if="bol">
        <el-input
          v-model="formInline.taxrate"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="请填写税率"
        >
          <template slot="suffix">%</template>
        </el-input>
      </el-form-item>
      <el-form-item
        v-if="bol"
        class="formItem"
        label-position="top"
        label="开户许可/基本存款信息表"
      >
        <span @click="dialogTableVisible = true"> 查看示例 </span>
        <el-upload
          :action="updata"
          :headers="{ ...headers, fileType: 'image' }"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :file-list="lyricFile"
           :on-success="onsuccess"
          accept="image/jpeg,image/png"
        >
          <img
            src="../assets/setbank.png"
            width="170px"
            height="100px"
            v-if="uploadfalg"
          />
          <div v-else style="width:170px; height:100px;background-size:100% 100%;" :style="{backgroundImage:`url(${formInline.imgurl})`}">
           <div class="mask">
            <img src="../assets/upload.png"  width="30px" height="30px" style="margin-top:20px;" >
            <p>重新上传</p>
           </div>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item class="form-item-btn">
        <el-button @click="$router.push('/income')">取消</el-button>
        <el-button class="bnt"  @click="onSubmit" :loading="submitbol"  >确定</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="提示" :visible.sync="dialogTableVisible">
      <div class="centent">
        <div>
          <p>基本存款信息表</p>
          <img src="../assets/MaskGroup.png" alt="" />
        </div>
        <div>
          <p>开户许可</p>
          <img src="../assets/GroupMask2.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from '../store'
export default {
  props:{
    identity:[String]
  },
  computed:{
    bol(){
      //true 为机构 false 为个人
      console.log(this.identity)
      return Number(this.identity)
    }
  },
  created () {
//    获取银行信息
    this.$post('/api/dict/dictInfo',{codes:['10014']}).then(res=>{
        this.options=res.data;
    })
   this.$post('/api/wallet/myBankMsg?type='+ (this.bol ? 0 : 1 )).then(res=>{
       if(res.data != null){
           this.uploadfalg=false
            this.formInline.name=res.data.name
            this.formInline.bank=res.data.bankName
            this.formInline.number=res.data.bankCard
            this.formInline.taxrate=res.data.invoiceTaxPoint
            this.formInline.imgurl=res.data.files
                          this.formInline.subbranch=res.data.bankAdd
            this.formInline.billingtype=res.data.invoiceType==1?'一般纳税人':res.data.invoiceType==0?'无':'小规模纳税人'
        //  bankAdd
       }
   })
  },
  data () {
    return {
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      updata: 'http://musicianplatform-api-test.hikoon.com/api/file/upload',
      lyricFile: [],
      //当前身份 true 为机构
      formInline: {
        // 开户名称
        name: '',
        // 开户银行
        bank: '',
        // 银行卡号
        number: '',
        //税率
        taxrate: '',
        // 支行
        subbranch: '',
        // 开票类型
        billingtype: '',
        imgurl:''
      },

      options: [],
      options1: [
        {
          type: '0',
          label: '无'
        },
        {
          type: '1',
          label: '一般纳税人'
        }, {
          type: '2',
          label: '小规模纳税人'
        }
      ],
      dialogTableVisible: false,
      uploadfalg: true,
    //   节流
    submitbol:false
    };
  },
  methods: {
    jump () {
      this.$router.push('/setbankcard/subbank')
    },
    onSubmit () {
        this.submitbol=true;
      if (this.bol) {
        // 机构银行卡修改
        let p={
            bankAddress:this.formInline.subbranch,
            bankCardNum:this.formInline.number,
            bankName:this.formInline.bank,
            invoiceTaxPoint:this.formInline.taxrate,
            invoiceType:this.formInline.billingtype=='无'?0:this.formInline.billingtype=='一般纳税人'?1:2,
            name:this.formInline.name,
            file:this.formInline.imgurl
        }

        this.$post('/api/company/bankSubmit', p).then(res => {
            this.submitbol=false;
          if(res.code==0){
              this.jump()
          }
        })

      }else{
           let p={
            bankAddress:this.formInline.subbranch,
            bankCardNum:this.formInline.number,
            bankName:this.formInline.bank,
            name:this.formInline.name
        }
        this.$post('/api/wallet/payCardUpdate',p).then(res=>{
         this.submitbol=false;
         if(res.code==0){
              this.jump()
          }
        })
      }
    },
    beforeUpload (val) {
      let ingetype = ['image/bmp', 'image/tiff', 'image/gif', 'image/png', 'image/jpeg', 'image/psd', 'image/eps']
      if (ingetype.indexOf(val.type) > -1) {
        return true
      } else {
        this.$popup({
          title: '错误',
          msg: '请上传正确的图片文件',
          type: 'error',
        });
        return false
      }
    },
    onsuccess(response){
      if(response.code==0){
          this.formInline.imgurl=response.url
         this.uploadfalg=false;
      }
    }
  }
}
</script>
<style scoped lang="scss">
.mask{
    width: 100%;
    height: 100%;
    background-color: rgba(12, 11, 11,0.5);
    >p{
        line-height:10px;
        color: red

    }
}
@import "../styles/initialization.scss";
::v-deep .el-select-dropdown {
  .el-select-dropdown__item {
    padding-left: 15px;
  }
}
.center {
  width: 360px;
  margin: 0px auto;
  ::v-deep .el-form {
    margin-bottom: 90px;
    .form-item-btn {
      margin-top: 40px;
      border: none !important;
      .el-form-item__content {
        float: none !important;
        button {
          cursor: pointer;
          border: none;
          width: 170px;
          height: 40px;
          opacity: 1;
          background: #f4f4f4;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: center;
          color: #333333;
          line-height: 14px;
        }
        .bnt {
          margin-left: 19px;
          background: rgba(255, 0, 77, 0.5);
          color: #ffffff;
          &:hover {
            background: rgba(255, 0, 77, 1);
          }
        }
      }
    }
    .formItem {
      border: none !important;
      height: 180px !important;
      .el-form-item__content {
        float: none !important;
        span {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #ff004d;
          line-height: 16px;
          display: block;
          float: right;
          margin-top: -40px;
          cursor: pointer;
        }
      }
    }
    .el-form-item {
      margin-right: 0px;
      width: 100%;
      height: 55px;
      border-bottom: 1px solid #e8e8e8;
      .el-form-item__content {
        display: block;
        float: right;
        .el-input-group {
          vertical-align: text-top;
        }
        .el-input__inner {
          vertical-align: text-top;
          border: none;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #666666;
          line-height: 16px;
        }
        .el-input-group__append {
          padding-left: 10px;
          padding-right: 0px;
        }
        .el-input__suffix {
          line-height: 60px;
        }
      }
      .el-form-item__label {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
    }
  }
}
::v-deep .el-dialog {
  width: 648px;
  height: 460px;
  .el-dialog__header {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: center;
    color: #222222;
    letter-spacing: 0px;
  }
  .centent {
    text-align: center;
    display: flex;
    justify-content: space-around;
    p {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      letter-spacing: 0px;
      margin-bottom: 18px;
    }
    img {
      width: 200px;
      height: 286px;
    }
  }
}
::v-deep .el-select-dropdown {
  .el-select-dropdown__item {
    padding-left: 15px;
  }
}
.select-div {
  padding-left: 15px;
  img {
    width: 26px;
    height: 26px;
    vertical-align: middle;
  }
  span {
    margin-left: 9px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    vertical-align: middle;
  }
}
</style>
